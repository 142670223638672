<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import { useRouteParams } from '@vueuse/router'

const { mainElement } = useAppElementRefs()

const workspaceId = useRouteParams<string>('workspaceId')
const showNav = useStorage('showNav', true)

const { y } = useScroll(mainElement)
</script>

<template>
  <NuxtLoadingIndicator />
  <slot name="topNav">
    <div class="fixed z-20 flex h-[68px] w-screen items-center bg-blue-50 transition-shadow duration-150 ease-in dark:bg-gray-950 print:hidden" :class="{ 'shadow-md': y > 0 }">
      <slot name="topNavContent">
        <TopNav v-if="workspaceId" :workspace-id="workspaceId" />
      </slot>
      <ImpersonationWarning />
    </div>
  </slot>
  <div class="flex min-h-0 flex-1 dark:bg-gray-900 print:block">
    <!-- review sidebar (ToC) should always be visible -->
    <div v-if="$slots.reviewLeftSidebar" class="flex max-w-64 flex-1 pt-[68px] print:hidden">
      <slot name="reviewLeftSidebar" />
    </div>
    <Transition v-else name="slide-in">
      <div v-show="showNav" class="flex max-w-64 flex-1 pt-[68px]">
        <Navigation />
      </div>
    </Transition>

    <main
      ref="mainElement"
      class="flex h-full flex-1 flex-col overflow-auto pt-[68px] dark:bg-gray-900 print:h-auto"
    >
      <slot />
    </main>

    <div v-if="$slots.rightSidebar" class="flex w-[17rem] flex-col overflow-y-auto bg-blue-50 pl-4 pt-[68px] dark:bg-gray-900 print:hidden">
      <slot name="rightSidebar" />
    </div>
  </div>
</template>

<style scoped>
.slide-in-enter-active {
  animation: slide-in 0.2s;
}
.slide-in-leave-active {
  animation: slide-in 0.2s reverse;
}
@keyframes slide-in {
  /* this one is wonky but animates main's mx-auto well */
  0% {
    margin-left: -16rem; /* max-w-64 */
  }
  100% {
    margin-left: 0;
  }
}
</style>
