<script setup lang="ts">
import ConfirmDialog from '../ConfirmDialog.vue'
import { DeleteWorkspaceDocument, PinWorkspaceDocument, UnpinWorkspaceDocument } from './Workspace.generated'

const props = defineProps<{
  workspaces: { id: string, label: string }[]
  globalWorkspaceId: string
  global: boolean
  pinnedWorkspaces: boolean
}>()

const { canChangeOrgConfig } = useCurrentUser()

const router = useRouter()
const route = useRoute('teamspace-workspaceId')
const currentWorkspaceId = computed(() => route.params.workspaceId)

const { enabled: surveysEnabled } = useFlag('SURVEY_M2')

function getSublinks(wId: string) {
  const links = [
    { label: 'Reviews', to: `/teamspace/${wId}/reviews`, icon: 'light:file' },
  ]

  if (surveysEnabled.value) {
    links.push({
      label: 'Surveys',
      to: `/teamspace/${wId}/surveys`,
      icon: 'light:square-poll-vertical',
    })
  }

  return links
}

const { execute: deleteWorkspace } = useMutation(DeleteWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: pinWorkspace } = useMutation(PinWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: unPinWorkspace } = useMutation(UnpinWorkspaceDocument, { refetchTags: ['workspaces'] })

const confirmDialog = ref<InstanceType<typeof ConfirmDialog>>()
const toBeDeletedWorkspaceName = ref('')

async function maybeDeleteWorkspace(workspace: { id: string, label: string }) {
  toBeDeletedWorkspaceName.value = workspace.label
  const { isCanceled } = await confirmDialog.value!.reveal()
  if (isCanceled)
    return

  await navigateTo({ name: 'teamspace-workspaceId-reviews', params: { workspaceId: props.globalWorkspaceId } })
  await deleteWorkspace({ id: workspace.id })
}

function items(workspace: { id: string, label: string }) {
  const orgSettings = [
    [
      {
        label: 'Organization settings',
        icon: 'light:gear',
        click: () => navigateTo({ name: 'settings-organization' }),
      },
      {
        label: 'Teamspace settings',
        icon: 'light:gear',
        click: () => navigateTo({
          name: 'settings-teamspace-teamspaceId-resource-allocation',
          params: { teamspaceId: workspace.id },
        }),
      },
    ],
  ]

  const nonGlobalSettings = [
    [
      {
        label: props.pinnedWorkspaces ? 'Unpin' : 'Pin',
        icon: 'light:thumbtack',
        click: () => props.pinnedWorkspaces ? unPinWorkspace({ id: workspace.id }) : pinWorkspace({ id: workspace.id }),
      },
      {
        label: 'Teamspace settings',
        icon: 'light:gear',
        click: () => navigateTo({
          name: 'settings-teamspace-teamspaceId',
          params: { teamspaceId: workspace.id },
        }),
      },
    ],
    [{
      label: 'Delete',
      icon: 'light:trash-can',
      click: () => { maybeDeleteWorkspace(workspace) },
    }],
  ]
  return props.global ? orgSettings : nonGlobalSettings
}

function handleAccordionClick(gid: string) {
  const [reviewsLink] = getSublinks(gid)
  router.push(reviewsLink.to)
}

function expanded(workspaceId: string) {
  return currentWorkspaceId.value === workspaceId
}
</script>

<template>
  <ConfirmDialog ref="confirmDialog" is-delete>
    <div>Are you sure you want to delete '{{ toBeDeletedWorkspaceName }}' teamspace?</div>
  </ConfirmDialog>

  <div class="flex w-full flex-col gap-y-2">
    <div v-for="workspace in workspaces" :key="workspace.id">
      <UButton color="white-nav" variant="ghost" active-class="dark:bg-gray-800 bg-gray-200" class="group mx-2 inline-flex w-[15.5rem] justify-between px-2" @click="handleAccordionClick(workspace.id)">
        <div class="flex items-center gap-x-2">
          <span class="text-left font-light text-gray-900 dark:text-white">{{ workspace.label }}</span>
          <UIcon
            name="solid:caret-right"
            class="text-sm text-gray-600 transition-transform duration-200 dark:text-gray-400"
            :class="{ 'rotate-90': expanded(workspace.id) }"
          />
        </div>
        <UDropdown
          v-if="!global || canChangeOrgConfig"
          :popper="{ offsetDistance: 12, offsetSkid: 6 }"
          :items="items(workspace)"
          :ui="{ width: 'w-48' }"
          @click.stop.prevent
        >
          <UButton
            icon="light:ellipsis"
            size="xs"
            color="gray"
            variant="nav"
            float="right"
            class="group-hover:visible"
            :class="{ invisible: !global }"
            data-testid="org-dropdown-button"
          />
        </UDropdown>
      </UButton>

      <TransitionHeight :visible="expanded(workspace.id)">
        <UVerticalNavigation
          :links="getSublinks(workspace.id)"
          class="mb-3 mt-1.5 pl-6 pr-2"
          :ui="{ padding: 'p-2', size: 'text-base', font: 'font-light', icon: { base: 'flex-shrink-0 size-4 relative' } }"
        />
      </TransitionHeight>
    </div>
  </div>
</template>
